import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TrainingGroupService } from '../../services/training-group.service';
import { AddExternalCertificateModel } from '../../models/person/add-external-certificate.model';
import { LoadingService } from '../../services/loading.service';
import { FileUtilService } from '../../services/file-util.service';
import { PersonService } from '../../services/person.service';
import { CompletedEventService } from '../../services/completed-event.service';
import { CompletedEventMemberService } from '../../services/completed-event-member.service';
import { ToastrService } from 'ngx-toastr';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { TrainingGroupModel } from '../../models/training-group.model';
import { TranslateService } from '@ngx-translate/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { GoogleMapsService } from '../../services/google-maps.service';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { TrainingTypeModel } from "../../models/training/training-type.model";

@Component({
  selector: 'app-add-external-certificate-dialog',
  templateUrl: './add-external-certificate-dialog.component.html',
  styleUrls: ['./add-external-certificate-dialog.component.scss'],
})
export class AddExternalCertificateDialogComponent implements OnInit {
  @ViewChild('addCertificateForm', { static: true })
  addCertificateForm!: DxFormComponent;
  @ViewChild(DxDropDownBoxComponent, { static: false })
  trainingGroupDropDown!: DxDropDownBoxComponent;
  isDropZoneActive = false;

  @Input()
  editingPersonId!: string;

  @Input()
  isEditMode!: boolean;

  @Input()
  certificate!: any;

  trainingTypeOptions!: DxSelectBoxTypes.Properties;

  @Output()
  onEventCreated = new EventEmitter();

  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(null, []),
    trainingType: new UntypedFormControl(null, [Validators.required]),
    iddHours: new UntypedFormControl(0, [Validators.required]),
    iddMinutes: new UntypedFormControl(0, [Validators.required]),
    date: new UntypedFormControl(null, [Validators.required]),
    location: new UntypedFormControl(null, []),
    trainingGroupId: new UntypedFormControl(null, []),
    certificateFile: new UntypedFormControl(null, []),
  });

  certificateForm: AddExternalCertificateModel = {
    name: '',
    description: '',
    trainingType: null,
    iddTimeMinutes: null,
    date: null,
    trainingGroupId: '',
    certificateFile: null,
    location: '',
    iddHours: 0,
    iddMinutes: 0,
  };
  trainingGroups: TrainingGroupModel[] = [];
  googleSearchText: string = '';
  googlePlaces: any[] = [];

  private googlePlaceinputChanged = new Subject<string>();
  googleAutocompleteResults$ = this.googlePlaceinputChanged.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((searchTerm) => this.mapsService.getAutocomplete(searchTerm))
  );

  constructor(
    private trainingGroupService: TrainingGroupService,
    private completedEventMemberService: CompletedEventMemberService,
    private toastrService: ToastrService,
    private fileUtilService: FileUtilService,
    private personService: PersonService,
    private completedEventService: CompletedEventService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private mapsService: GoogleMapsService
  ) {
    this.onSelectedFileChange = this.onSelectedFileChange.bind(this);
    this.autocompleteSearchFilter = this.autocompleteSearchFilter.bind(this);
    const result = this.trainingGroupService.getAllTrainingGroups();
    this.loadingService.load(result).subscribe((trainingGroups) => {
      this.trainingGroups = trainingGroups;
      this.setDropdownOptions();
    });
  }

  ngOnInit(): void {
    this.googleAutocompleteResults$.subscribe((results) => {
      this.googlePlaces = results;
    });

    if (this.isEditMode) {
      this.certificateForm.name = this.certificate.title;
      this.certificateForm.description = this.certificate.description;
      this.certificateForm.trainingType = this.certificate.trainingType;
      this.certificateForm.iddTimeMinutes = this.certificate.iddTimeMinutes;
      this.certificateForm.date = new Date(this.certificate.date);
      this.certificateForm.trainingGroupId =
        this.certificate.trainingGroups && this.certificate.trainingGroups[0]
          ? this.certificate.trainingGroups[0].id
          : null;
      this.certificateForm.location = this.certificate.location;
      this.certificateForm.iddHours = this.certificateForm.iddTimeMinutes
        ? Math.floor(this.certificateForm.iddTimeMinutes / 60)
        : 0;
      this.certificateForm.iddMinutes = this.certificateForm.iddTimeMinutes
        ? this.certificateForm.iddTimeMinutes % 60
        : 0;
      this.googleSearchText = this.certificate.location;
    }
  }

  setDropdownOptions() {
    const defaultSelectOptions = {
      showClearButton: true,
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id',
    } as DxSelectBoxTypes.Properties;

    this.trainingTypeOptions = {
      ...defaultSelectOptions,
      dataSource: [
        {
          id: 0,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.IDD'
          ),
        },
        {
          id: 1,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
          ),
        },
        {
          id: 2,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
          ),
        },
      ],
      placeholder: '',
    };
  }

  async onSelectedFileChange(e: any) {
    if (e[0] && e[0].name.endsWith('.pdf')) {
      this.certificateForm.certificateFile =
        await this.fileUtilService.convertFileToRawFileModel(e[0]);

/*
        this.loadingService
        .load(
          this.completedEventService.extractCertificateFile(
            this.certificateForm.certificateFile
          )
        )
        .subscribe((certificateData) => {
          this.certificateForm.name = certificateData.title;
          this.certificateForm.description = certificateData.description;
          this.certificateForm.trainingType = TrainingTypeModel[certificateData.training_type as keyof typeof TrainingTypeModel];
          this.certificateForm.date = new Date(certificateData.date);
          this.certificateForm.location = certificateData.location;
          this.googleSearchText = certificateData.location;
          this.certificateForm.iddHours = certificateData.idd_time ? Math.floor(
            certificateData.idd_time / 60
          ) : 0;
          this.certificateForm.iddMinutes = certificateData.idd_time ? certificateData.idd_time % 60 : 0;

          const trainingGroup = this.trainingGroups.find(group => group.name === certificateData.training_group);
          if (trainingGroup) {
            this.certificateForm.trainingGroupId = trainingGroup.id;
          }
        });
*/
    } else {
      this.certificateForm.certificateFile = null;
    }
    this.isDropZoneActive = false;
  }

  onTrainingGroupSelected(e: any) {
    if (e.node.key) {
      this.certificateForm.trainingGroupId = e.node.key;
    }
  }

  onFormSubmit() {
    const isCertificateFormValid =
      this.addCertificateForm.instance.validate().isValid;
    const isCustomFieldsValid =
      validationEngine.validateGroup('customFields').isValid;

    if (isCertificateFormValid && isCustomFieldsValid) {
      const date: Date = this.certificateForm.date!;
      date.setHours(12);

      if (this.certificateForm.trainingType !== 0) {
        this.certificateForm.iddTimeMinutes = 0;
      } else {
        this.certificateForm.iddTimeMinutes =
          this.certificateForm.iddHours! * 60 +
          this.certificateForm.iddMinutes!;
      }

      const certificateModel: AddExternalCertificateModel = {
        ...this.certificateForm,
        date,
      };

      if (!certificateModel.trainingGroupId) {
        certificateModel.trainingGroupId = null;
      }

      delete certificateModel.iddHours;
      delete certificateModel.iddMinutes;

      if (!this.isEditMode) {
        this.loadingService
          .load(
            this.personService.addExternalCertificate(
              this.editingPersonId,
              certificateModel
            )
          )
          .subscribe({
            next: () => {
              this.toastrService.success(
                this.translateService.instant(
                  'EMPLOYEE.CERTIFICATES.CERTIFICATE-CREATED'
                )
              );
              this.onEventCreated.emit();
            },
            error: () => {
              this.toastrService.error(
                this.translateService.instant(
                  'EMPLOYEE.CERTIFICATES.CERTIFICATE-ERROR'
                )
              );
            },
          });
      } else {
        this.loadingService
          .load(
            this.completedEventMemberService.updateCertificate(
              this.certificate.id,
              certificateModel
            )
          )
          .subscribe(() => {
            this.onEventCreated.emit();
          });
      }
    }
  }

  handleTimePicker(value: { hours: number; minutes: number }) {
    this.certificateForm.iddHours = value.hours;
    this.certificateForm.iddMinutes = value.minutes;
  }

  autocompleteSearchFilter(e: any) {
    return this.googleSearchText;
  }

  getGooglePlaces(e: any) {
    this.googlePlaceinputChanged.next(e.value);
  }

  setLocationPlace(e: any) {
    this.loadingService
      .load(this.mapsService.getDetails(e.itemData.place_id))
      .subscribe((details) => {
        const location = details.formatted_address.startsWith(details.name)
          ? details.formatted_address
          : details.name + ', ' + details.formatted_address;
        this.certificateForm.location = location;
      });
  }

  onDropZoneEnter({ component, dropZoneElement, event }: any) {
    if (dropZoneElement.id === 'dropzone-external') {
      this.isDropZoneActive = true;
    }
  }

  onDropZoneLeave(e: any) {
    if (e.dropZoneElement.id === 'dropzone-external') { this.isDropZoneActive = false; }
  }

  onUpload(event: any) {
    console.log('Uploaded files:', event);
  }
}
